/* header component css */
.headerContainer {
    position: fixed;
    top: 0;
    background: white;
    width: 100%;
    z-index: 99;
    line-height: 18px;
}

.headerContainer nav {
    display: block;
    align-items: center;
    padding: 16px 24px;
    text-align: center;

}

.headerContainer h1 {
    font-size: 24px;
    font-weight: 700;
    /* margin-right: 16px; */

}


.searchIcon {
    font-size: 24px;
    color: #d50c0c;
    margin-right: 5px;
}

.filterIcon {
    font-size: 18px;
    margin-left: 15px;
    padding: 8px;
    color: #666666;
    border-radius: 6px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
}

.resetBtn {
    text-align: center;
    color: #646464;
    font-size: 16px;
    font-weight: 600;
}

.removeSearchText {
    color: rgb(255, 126, 126);
}

/* menu list */
.menuCategoryContainer,
.scrollComponent {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

::-webkit-scrollbar {
    width: 0;
    background: transparent;
}


.categoryList {
    height: 290px;
    overflow-y: scroll;
    list-style: none;
    margin-left: -35px;
    margin-bottom: 0px;
}

.categoryList li {
    padding: 10px 5px;
    border-radius: 10px;
    font-size: 15px;
    font-weight: 500;
    color: black;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-align: left;
    cursor: pointer;
}

.itemImgContainer {
    padding: 10px;
}

.itemImgContainer img {
    width: 100px;
    height: 100px;
    border-radius: 12px;
    object-fit: cover;
}

.viewMoreIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    margin-top: -10px;
}


/* all items card */
.cardContainerRow {
    padding: 120px 0px 100px 0px;
}

.allMenuItemContainer {
    /* background: #fbe3e3; */
    margin: 12px 24px;
    border-radius: 12px;
    box-shadow: 0px 1.163424015045166px 17.451356887817383px 0px #0000001a;
    display: flex;
    flex-direction: row-reverse;
    height: auto;
}

.sideImgContainer {
    width: 50%;
    margin: auto;
    padding: 24px 0px;
}

.itemImageBox,
.filterAndSearchBox {
    position: relative;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center
}

.itemImage {
    width: 100%;
}

.itemImage img {
    object-fit: cover;
    width: 130px;
    height: 130px;
    border-radius: 12px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.actionIcons {
    position: absolute;
    top: -14px;
    right: -8px;
    /* background: #e23939; */
}

.fillHeartIcon {
    font-size: 25px;
    color: #d50c0c;
}

.antIcon {
    font-size: 25px;
    color: rgb(252, 252, 252);
}

.popoverIcons {
    font-size: 15px;
}

.itemDescription {
    padding: 15px;
    width: 50%;
}

.descriptionText {
    color: #3a3a3a;
    margin-top: 10px;
}

.itemDescription p {
    font-size: 14px;
    justify-content: center;
    line-height: 22px;
    word-break: break-all;
}

.readMoreOption {
    font-size: 12px;
    color: #4c4c4c;
    font-weight: 600;
    cursor: pointer;
}

.itemPrice {
    display: flex;
}

.itemPrice p {
    color: rgb(3 144 199);
}

.price {
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    margin-right: 20px;
    margin-bottom: 10px;
}

.productOption,
.optionFilter {
    display: flex;
}

.optionFilter p,
.resetBtn p {
    margin-bottom: 0;
}

.vegFood {
    width: 18px;
    height: 18px;
    border: 1px solid rgb(3, 153, 3);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    border-radius: 4px
}

.vegFood span {
    width: 65%;
    display: flex;
    height: 64%;
    background-color: green;
    border-radius: 50%;
}

.nonVegFood {
    width: 18px;
    height: 18px;
    border: 1px solid red;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    border-radius: 4px
}

.nonVegFood span {
    width: 65%;
    display: flex;
    height: 64%;
    background-color: red;
    border-radius: 50%;
}

.eggFood {
    width: 18px;
    height: 18px;
    border: 1px solid #fdac17;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    border-radius: 4px
}

.eggFood span {
    width: 65%;
    display: flex;
    height: 64%;
    background-color: #fdac17;
    border-radius: 50%;
}

.optionGroup {
    border: 1px solid #ff3c00;
    padding: 1.5px;
    color: #ff3c00;
}

.descriptionHead,
.itemDrawerFooter,
.CartItemCounterBox,
.addItemCounter,
.disabledItemCounter {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.descriptionHead h2 {
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    margin-top: 10px;
}

.addItemBox {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -15px;
}

.addItemBox p {
    margin-top: 15px;
}

.addBtn button,
.cancelOrderBtn {
    color: #d50c0c;
    background: #fff1f0;
    border: 1px solid#d50c0c;
    height: 35px;
}

.addBtn button:hover,
.addBtn button:focus,
.addBtn button:active,
.cancelOrderBtn:hover,
.cancelOrderBtn:focus,
.cancelOrderBtn:active {
    background: #fae3e1;
    color: #d50c0c;
    border: 1px solid #d50c0c;
}

.categoryType p {
    color: #ffffff;
    font-size: 10px;
    line-height: 19px;
    letter-spacing: 0.02rem;
    background: rgb(211 167 5);
    margin-bottom: 0px;
    border-radius: 4px;
    margin-inline-end: 8px;
    padding-inline: 7px;
    width: fit-content;
}

/* add item drawer css */
.addItemImgContainer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.addItemImgContainer img {
    width: 60px;
    height: 60px;
    object-fit: cover;
    border-radius: 12px;
    margin-right: 15px;
}

.addItemImgContainer p {
    margin-top: 15px;
}

.itemOptionContainer,
.itemInstructionContainer {
    border-radius: 12px;
    padding: 5px 10px 15px 10px;
    background: white;
    box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
    /* margin-bottom: 24px; */
}

.itemOptionContainer {
    margin-bottom: 24px
}

.itemOptionContainer>div,
.itemInstructionContainer>div {
    margin-bottom: 0px
}


.itemOptionInputFields>div>div>label,
.itemInstructionContainer>div>div>div>label,
.instructionInput label {
    padding: 10px;
    width: 100%;
    font-size: 16px !important;
    font-weight: 600;
}

.itemOptionInputFields>div>div>label {
    border-bottom: 0.5px solid #d9d9d9;
}

.radioItems,
.CheckBoxItems,
.CheckBoxItems label {
    width: 100%;
}

.radioItems>span:nth-child(2),
.CheckBoxContent>span:nth-child(2) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.itemOption {
    display: grid;
    align-items: center;
}

.itemOption label {
    padding: 5px;
}

.itemOption label:first-child {
    margin-inline-start: 8px;
}

.totalItemCountInput {
    display: none;
}

.totalItemBox {
    width: 50%;
    padding: 10px 0px;
}

.totalItemBox>div,
.addItemsCart>div {
    margin-bottom: 0px;
}

.totalItem {
    display: flex;
    align-items: center;
    font-size: 14px;
    padding: 5px 10px;
    border: 1px solid #e2e2e2;
    border-radius: 12px;
    width: fit-content;
}


.quantityInput {
    width: 60px;
    text-align: center;
    border: none;
    background: #fff1f0;
    border-top: 1px solid #d50c0c;
    border-bottom: 1px solid #d50c0c;
    border-radius: 0px;
    height: 40px;
}

.addItemCartBox,
.viewMenu {
    display: flex;
    justify-content: center;
    margin-top: -14px;
}

.addItemCart {
    display: flex;
    align-items: center;
    position: absolute;
    z-index: 99;
    font-size: 14px;
    padding: 5px 15px;
    border: 1px solid #d50c0c;
    border-radius: 6px;
    width: fit-content;
    background: #d50c0c;
    color: white;
    height: 35px;

}

.totalItemCount {
    margin: 0px 10px;
    color: black;
}

.addItemsCart button,
.cartDetailBtn button,
.personalInfoBtn button {
    display: flex;
    align-items: center;
    font-size: 16px;
    border: 1px solid #d50c0c;
    border-radius: 10px;
    width: fit-content;
    background: #d50c0c;
    color: white;
    font-weight: 600;
    padding: 20px 24px;
    justify-content: center;

}

.addItemsCart button.ant-btn:hover {
    background: #fae3e1;
    color: #d50c0c;
    border: 1px solid #d50c0c;
}

.pageFooter {
    z-index: 999;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
}

.menuContainer {
    position: relative;
    display: flex;
    align-items: center;
    width: fit-content;
    background: #000000cf;
    color: #ffffff;
    padding: 10px 15px;
    border-radius: 14px;
    cursor: pointer;
}


.menuListModal {
    top: calc(55vh - 50px);
    /* position: absolute; */
    /* top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); */
}

.viewMenu {
    margin-bottom: 10px;
}

.viewMenu p {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 0;
}

.menuCategoryList {
    position: fixed;
    overflow-y: scroll;
}

.viewCartDetailFooter {
    background: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 14px 20px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.menuIcon img {
    width: 35px;
    height: 35px;
    margin-right: 10px;
}

.cartIcon {
    font-size: 25px;
    margin-right: 10px;
}

.cartItems {
    width: 55%;
    display: flex;
    font-size: 18px;
    font-weight: 600;
    color: black;
}

.cartItems p {
    margin-bottom: -2px;
    margin-top: 4px;
}

.cartDetailBtn a {
    text-decoration: none;
}

.cartDetailBtn button {
    padding: 10px 24px;
}

/* view cart page */

.cartDetailContainer {
    padding: 0px 24px 24px 24px;
}

.cartSummeryContainer {
    padding-bottom: 50px;
}

.cartItemsCard {
    box-shadow: 0px 1.163424015045166px 17.451356887817383px 0px #0000001a;
    border-radius: 12px;
    margin-bottom: 20px;
}

.cardDivider {
    margin: 15px 0px;
}

.customerInfoForm>div,
.instructionInput {
    margin-bottom: 10px;
}

.cartItemCounter,
.addItemCounter {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: fit-content;
    padding: 10px 15px;
    color: #d50c0c;
    background: #fff1f0;
    border: 1px solid #d50c0c;
    border-radius: 6px;
    height: 30px;
    margin-bottom: 10px;
}

.addItemCounter {
    height: 40px;
    margin-top: 10px
}


.disabledItemCounter {
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: fit-content;
    padding: 10px 15px;
    color: #9d9c9c;
    background: #efefef;
    border: 1px solid #cdcdcd;
    border-radius: 6px;
    height: 30px;
    margin-bottom: 10px;
    height: 40px;
    margin-top: 10px
}

.disabledItemCounter p {
    padding: 10px 25px;
    margin-bottom: 0px;
    font-size: 16px;
}

.addInfoCartBtns {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.borderNone {
    border: none !important;
}

.addInfoCartBtns>div {
    margin-bottom: 0px;
    padding: 10px 0px;
}

.cartItemDetail,
.totalItemPrice {
    font-weight: 600;
}

.cartItemDetail p {
    color: black
}

.addMoreItems {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
}

.addMoreItems p {
    margin-bottom: 0px;
}

.addMoreItems span {
    font-size: 20px;
    margin-right: 10px;
}

.subTotal {
    font-size: 16px;
    font-weight: 600;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.CartBillBox {
    font-size: 18px;
    font-weight: 600;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.CartBillBox p {
    margin-bottom: 0px;
}

.personalInfoBtn {
    padding: 14px 20px;
    background: white;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.personalInfoBtn button {
    width: 100%;
}

.cancelOrderBtn {
    height: 39px;
    font-size: 16px;
    margin-right: 16px;
    border-radius: 10px;
}


.SkeletonContainer {
    padding: 20px;
}

.cardSkeleton {
    display: flex;
}




@media only screen and (max-width: 335px) {
    .itemDrawerFooter {
        display: block;
    }
}

@media only screen and (max-width: 352px) {

    /* .itemDrawerFooter {
        display: block;
    } */

    .totalItemBox,
    .addItemsCart button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }

    .cartDetailBtn {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .cartDetailBtn button {
        margin-top: 10px;
    }
}



/* new Card Design (veg-nonve and Tag on top) */
.productCardBox {
    margin: 12px 24px;
    border-radius: 12px;
    box-shadow: 0px 1.163424015045166px 17.451356887817383px 0px #0000001a;
    height: auto;
}

.MenuItemContainer {
    /* background: #fbe3e3; */
    display: flex;
    flex-direction: row-reverse;

}

.categoryOptionBox {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 15px 0px 15px;
}

.itemAllDescription {
    padding: 0px 15px 15px 15px;
    width: 50%;
}


.itemAllDescription p {
    font-size: 14px;
    justify-content: center;
    line-height: 22px;
    word-break: break-word;
}

.sideImgbox {
    width: 50%;
    margin: auto;
    padding: 10px 10px 24px 0px;
}

.productItemImage img {
    object-fit: cover;
    width: 115px;
    height: 115px;
    border-radius: 12px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.description {
    color: #3a3a3a;
    margin: 0px;
}

.cardColumns {
    margin: auto;
}