/* order complete page */
.orderCompleteBox,
.textWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.orderCompleteBox {
    height: 60vh;
}

.orderCompleteContent {
    margin: 20px;
    box-shadow: 0px 1.163424015045166px 17.451356887817383px 0px #0000001a;
    padding: 30px 10px;
    width: 90%;
    border-radius: 20px;
}

.orderCompleteText,
.headingText {
    font-size: 22px;
    font-weight: 700;
    text-align: center;
}

.thanksForOrder {
    margin-bottom: 20px;
}

.thanksForOrder img {
    width: 100%;
    object-fit: contain;
    height: 100px;
}


.SuccessOrder {
    width: 100%;
    object-fit: contain;
}

.thankYouText {
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    /* color: rgb(209, 2, 37); */
}


/* closed msg page */

.timeText {
    text-align: center;
    font-size: 18px;
    line-height: 25px;
}