/* no food items found page */
.noFoodItemFound {
    margin-top: 50px;
}

.noFoodItemFound img {
    width: 100%;
    object-fit: contain;
    height: 200px;
}

/* 
@media only screen and (min-width: 900px) {
    .noFoodItemFound img {
        width: 100%;
        height: 100px;
    }
} */