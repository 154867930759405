body.modal-opened {
    overflow: hidden;
}

.ant-divider .ant-divider-inner-text {
    letter-spacing: 0.05rem;

}

.ant-divider-horizontal .ant-divider-with-text,
.ant-divider-horizontal {
    margin: 10px 0;
}

label.ant-checkbox-wrapper.ant-checkbox-wrapper-in-form-item.ant-checkbox-group-item {
    margin-inline-start: 0px;
}

.ant-btn-default:not(:disabled):hover {
    background: #d50c0c;
    color: white;
    border: 1px solid #d50c0c;
}

.activeCategory {
    color: #d50c0c !important;
    font-weight: 600 !important;
    /* box-shadow: 0px 1.163424015045166px 17.451356887817383px 0px #0000001a; */
    cursor: pointer;
    font-size: 16px !important;
}

.ant-dropdown.menuCategoryList.ant-dropdown-placement-top {
    min-width: 43vh !important;
}

.menuCategoryList {
    min-width: 47vh;
    left: 99px;
    top: 115px;
    max-height: 300px;
    overflow-y: scroll;
    position: fixed;
}

.menuCategoryList p {
    margin-bottom: 0;
    font-size: 16px;
}

.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item-selected:hover {
    background-color: #ededed;
}

.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item-selected {
    color: #000000;
    background-color: #ededed;
    font-weight: 600;
}

.ant-input-group .ant-input-affix-wrapper:not(:last-child) {
    border-radius: 12px !important;
    border: none;
    /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
}

.ant-input-search>.ant-input-group>.ant-input-group-addon:last-child {
    display: none;
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-color: #d50c0c;
    border-inline-end-width: 1px;
    z-index: 1;
}

.ant-input-affix-wrapper-focused {
    box-shadow: 0 0 0 2px rgba(255, 5, 5, 0.1);
}

.ant-skeleton.ant-skeleton-element {
    display: inline-block;
    width: 50%;
    padding: 24px;
}

.ant-modal .ant-modal-content {
    padding: 15px 10px;
}

.ant-drawer .ant-drawer-header {
    padding: 10px;
}

.ant-drawer .ant-drawer-content-wrapper {
    /* min-height: 355px !important; */
    height: inherit !important;
}

.ant-drawer .ant-drawer-content-wrapper .ant-drawer-body {
    height: auto !important;
    padding: 16px;
    /* background: #f5f5f5d1; */
    max-height: 400px !important;
    overflow-y: scroll;
}

.addItemDrawer .ant-drawer-content-wrapper .ant-drawer-body {
    padding: 24px 16px;
    background: #f5f5f5d1;
}

.ant-space-vertical {
    width: 100%;
}

.ant-modal .ant-modal-title {
    font-size: 18px;
}

.ant-modal .ant-modal-content {
    border-radius: 20px;
}


.ant-btn-primary {
    color: #fff;
    background-color: #d50c0c;
    box-shadow: 0 2px 0 rgba(255, 5, 5, 0.1);
}

.ant-input:hover {
    border-color: #d50c0c;
    border-inline-end-width: 1px;
}

.ant-radio-wrapper .ant-radio-checked .ant-radio-inner,
.ant-checkbox-checked .ant-checkbox-inner {
    border-color: #d50c0c;
    background-color: #d50c0c;
}

.ant-checkbox-checked:after {
    border: 2px solid #d50c0c;
}

.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner {
    background-color: #d50c0c;
    border-color: transparent
}

.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-inner,
.ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inner,
.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-checked:not(.ant-checkbox-disabled):after {
    border-color: #d50c0c;
}

/* demo menu drawer */
.menuDrawer .ant-drawer-content-wrapper {
    min-height: 430px !important;
    height: inherit !important;
    margin: 0px 15px;
}

.menuDrawer .ant-drawer-content-wrapper .ant-drawer-body {
    height: auto !important;
    padding: 0px 16px 16px 16px;
    max-height: 270px !important;
    overflow-y: scroll;
}

.menuDrawer .ant-drawer-content {
    border-radius: 24px;
}


.itemDrawerClass .ant-drawer-wrapper-body .ant-drawer-body {
    display: none
}

@supports (-webkit-overflow-scrolling: touch) {
    input {
        font-size: 16px;
    }
}

/* Fix Input Zoom on iPhone 6 Plus, iPhone 6s Plus, iPhone 7 Plus, iPhone 8, iPhone X, XS, XS Max  */
@media screen and (device-aspect-ratio: 9/16) {

    select,
    textarea,
    input[type="text"],
    input[type="password"],
    input[type="datetime"],
    input[type="datetime-local"],
    input[type="date"],
    input[type="month"],
    input[type="time"],
    input[type="week"],
    input[type="number"],
    input[type="email"],
    input[type="tel"],
    input[type="url"] {
        font-size: 16px;
    }
}